<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}

</style>
<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="ID"
          label="name"
          @search-change="getCustomer"
        >
          <!-- <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <div class="vx-row margin-btm w-full" style="width: 100%;padding-bottom:15px;">
          <label class="vs-input--label">CN Promotion Type</label>
          <multiselect
            class="selectExample"
            v-model="create.cnPromotionType"
            :options="option.cnPromotionType"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            :disabled="false"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{ dt.option.Name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{ dt.option.Name  }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/2 w-full">
        
        Customer : ({{create.customer.code}}) {{create.customer.name}}<br>
        Customer Channel : ({{create.customer.customer_category.name}}) {{create.customer.customer_category.classification}} <br>
        Customer Tax Number : {{create.customer.npwp?create.customer.npwp:"-"}}<br>
        Customer Tax Name : {{create.customer.npwp_name ?create.customer.npwp_name :"-"}}<br>
        Customer Tax Type :  {{create.customer.npwp_type}}<br>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <div v-if="create.cnPromotionType != null" class=" margin-btm w-full" style="width: 100%;padding-bottom:15px;">
          <!-- {{create.cnPromotionType.RateOption}} -->
          <template v-for="rate in create.cnPromotionType.RateOption">
            <div v-bind:key="rate">
              Withholding Tax Rate {{rate.NpwpType}} : {{rate.Rate}} % <br>
            </div>
          </template>
            <template >
              WH Tax Calculation : {{withholdingTax}}
            </template>
        </div>
        <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
          <div class="vx-col w-full">
            <label class="vs-input--label">CN Date</label>
            <datepicker
              class="w-full"
              v-validate="'required'"
              :inline="false"
              name="CN Date"
              v-model="create.cnDate"
              placeholder="CN Date"
              :disabled-dates="{from: new Date()}"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row margin-btm w-full" style="width: 100%;padding-bottom:15px;">
          <div class="vx-col w-full">
            <label class="vs-input--label">CN Posting Date</label>
            <datepicker
              class="w-full"
              v-validate="'required'"
              :inline="false"
              name="CN Posting Date"
              v-model="create.cnPostingDate"
              placeholder="CN Posting Date"
              :disabled-dates="{from: new Date()}"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
          <div class="vx-col w-full">
            <label class="vs-input--label">VAT IN</label>
            <div class="vx-col sm:w-1/6">
                <input type="radio" v-model="create.vat_in" value="1" >
                <label> Yes</label>
            </div>
            <div class="vx-col sm:w-1/6">
                <input type="radio" v-model="create.vat_in" value="0" >
                <label> No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/2 w-full">
      
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="create.territory"
          :options="option.territory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="territory_id"
          label="territory_name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.territory_code + ' ' + dt.option.territory_name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.territory_code + ' ' + dt.option.territory_name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Customer Tax Return Number</label>
        <vs-input class="w-full" v-model="create.taxReturnNumber"/>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/2 w-full">
       <label class="vs-input--label">Operating Unit</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnit"
          :options="option.operatingUnit"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="operating_unit_id"
          label="operating_unit_name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.operating_unit_code + ' ' + dt.option.operating_unit_name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.operating_unit_code + ' ' + dt.option.operating_unit_name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Customer Tax Return Date</label>
        <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="CN Posting Date"
            v-model="create.taxReturnDate"
            placeholder="CN Posting Date"
            :disabled-dates="{from: new Date()}"
          ></datepicker>
      </div>
    </div>
    <div v-if="selected.ID!=0" class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Note Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{selected.Code}}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">DN</label>
        <multiselect
          class="selectExample"
          v-model="create.debitNote"
          :options="option.debitNote"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="ID"
          label="Code"
          @search-change="getDebitNote"
        >
          <!-- <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col sm:w-1/2 w-full">
        <label class="vs-input--label">Settlement CC</label>
        <multiselect
          class="selectExample"
          v-model="create.costCenter"
          :options="option.costCenter"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="Name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ '(' + dt.option.Code + ') ' + dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ '(' + dt.option.Code + ') ' + dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
        
    </div>
    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Notes</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea
          class="w-full"
          label="Note"
          name="Note"
          v-model="create.notes"
          placeholder="Note"
        />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr,i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="handleShow(tr)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>

    <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">DPP</label>
        <vs-input
          class="w-full"
          v-model="create.dpp"
          @keyup="create.dpp = formatPrice(create.dpp.toString())"
        />
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">VAT IN</label>
        <vs-input
          :readonly="create.vat_in==0"
          :class="create.vat_in==0?'input-readonly w-full':'w-full'"
          v-model="create.vatInValue"
          @keyup="create.vatInValue = formatPrice(create.vatInValue.toString())"
        />
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">Withholding Tax Value</label>
        <vs-input
          class="w-full"
          readonly
          v-model="create.withholdingTaxValue"
        />
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <label class="vs-input--label">CN Value</label>
        <vs-input
          class="w-full input-readonly "
          readonly
          :value="create.amount"
          
        />
      </div>
      
    </div>
    <!-- <div class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Note</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="create.note"></vs-textarea>
      </div>
    </div> -->

    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

    </div>
  </div>
</template>
<script>
import {
  dataCustomer
} from "../../../../services/api/payment";
import {
  createCreditNoteManual,
} from "../../../../services/api/credit_note";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Datepicker from "vuejs-datepicker";
import moment from 'moment'
export default {
  components: {
    // flatPickr,
    Datepicker
  },
  props: {
    selected: Object,
  },
  data() {
    return {
      fileAttachment:[],
      file:[],
      fileTemp:[],
      create: {
        id:0,
        customer:{ID:0,customer_category:{}},
        territory:null,
        operatingUnit:null,
        cnDate: null,
        cnPromotionType:null,
        cnPostingDate:null,
        debitNote:null,
        customer_code: "",
        costCenter:null,
        vat_in:0,
        amount: 0,
        taxReturnNumber: '',
        taxReturnDate: '',
        notes: '',
        dpp:0,
        withholdingTaxValue:0,
        vatInValue:0,
      },
      withholdingTax:'-',
      immutable:true,
      option: {
        customer: [],
        territory: [],
        operatingUnit: [],
        cnPromotionType: [],
        costCenter:[],
        debitNote:[],
        withholdingTax:[],
      },
      
    };
  },
  mounted() {
    this.getOptionOU()
    this.getGeneralForm()
    console.log(this.selected,"this.selected")
    if (this.selected.ID == 0) {
      this.create.taxReturnDate =  moment(new Date()).format("YYYY-MM-DD");
      this.create.cnDate =  moment(new Date()).format("YYYY-MM-DD");
      this.create.cnPostingDate =  moment(new Date()).format("YYYY-MM-DD");
      this.$nextTick(() => {
        this.immutable = false
      })
    } else {
      this.create.id = this.selected.ID
      this.create.cnDate =  moment(this.selected.Date).format("YYYY-MM-DD");
      this.create.cnPostingDate =  moment(this.selected.PostingDate).format("YYYY-MM-DD");
      this.create.taxReturnDate =  moment(this.selected.TaxReturnDate).format("YYYY-MM-DD");
      this.create.taxReturnNumber =  this.selected.TaxReturnNumber
      this.create.withholdingTaxValue = this.formatPrice(this.selected.WithholdingTaxValue)
      this.create.vatInValue = this.formatPrice(this.selected.TaxValue)
      if (this.selected.TaxValue > 0) {
        this.create.vat_in = 1
      }
      this.create.amount = this.formatPrice(this.selected.TotalValue)
      this.create.id = this.selected.ID
      this.create.customer_code = this.selected.CustomerCode
      this.create.dpp = this.formatPrice(this.selected.DPP)
      this.create.notes = this.selected.Note
      this.fileAttachment = this.selected.Attachment
      console.log(this.selected)
      this.getCustomer(this.selected.CustomerCode)
      this.findDebitNote(this.selected.DebitNoteID)
      this.$nextTick(() => {
        this.immutable = false
      })
    }
      
  },
  watch: {
    "create.customer": function () {
      if (this.create.customer.customer_territory.length > 0) {
        this.option.territory.map(r => {
          if (r.territory_id == this.create.customer.customer_territory[0].territory_id) {
            this.create.territory = r
          }
        })
        
      }
      this.option.withHoldingTax.map(v => {
        if (v.customer_category_id == this.create.customer.customer_category_id) {
          this.withholdingTax = v.calculation_method
        }
      })
      this.handleRate()
    },
    "create.territory": function () {
      // if (!this.immutable) {
      //   console.log("immutable")
      //   this.create.operatingUnit = null
      // }
      this.option.operatingUnit = this.option.territory.filter(r => {
          return r.territory_id == this.create.territory.territory_id
      })
      const check = this.option.operatingUnit.filter(r => {
         return r.operating_unit_id == this.create.operatingUnit.operating_unit_id
      })
      if (check.length < 1) {
        this.create.operatingUnit = null
      }
    },
    "create.dpp": function () {
      
      if (!this.immutable) {
        // if (create.this.customer.customer_category.name == 'MT') {
          this.create.withholdingTaxValue = this.create.dpp.toString().replace(/,/g, '')* (this.create.cnPromotionType.Rate/100)
          this.create.withholdingTaxValue = this.formatPrice(this.create.withholdingTaxValue.toString().replace(/,/g, ''))
          console.log(this.create.customer.customer_category.name, 'iniiii',this.create.dpp,parseFloat(this.create.dpp.toString().replace(/,/g, '')), this.create.withholdingTaxValue )
          if (this.withholdingTax == "GROSS") {
            this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, ''))
          } else {
            this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, '')) - parseFloat(this.create.withholdingTaxValue.toString().replace(/,/g, ''))
            console.log(this.create.amount)
          }
          this.create.amount = this.formatPrice(this.create.amount.toString().replace(/,/g, ''))
      }
    },
    "create.cnPromotionType.ID": function () {
      console.log("create.cnPromotionType")
      this.handleRate()
    },
    "create.vatInValue": function () {
        if (this.withholdingTax == "GROSS") {
          this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, ''))
        } else {
          this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, '')) - parseFloat(this.create.withholdingTaxValue.toString().replace(/,/g, ''))
        }
        this.create.amount = this.formatPrice(this.create.amount.toString().replace(/,/g, ''))
    },
    "selected.ID": function () {
      // this.create = this.selected
      this.create.id = this.selected.ID
      this.create.customer_code = this.selected.CustomerCode
      this.create.cnDate =  moment(this.selected.Date).format("YYYY-MM-DD");
      this.create.cnPostingDate =  moment(this.selected.PostingDate).format("YYYY-MM-DD");
      this.create.taxReturnDate =  moment(this.selected.TaxReturnDate).format("YYYY-MM-DD");
      // {
      //   id: this.selected.ID,
      //   customer_code: this.selected.CustomerCode,
      //   amount: this.formatPrice(this.selected.TotalValue),
      //   customer:{ID:0,customer_category:{}},
      //   territory:null,
      //   operatingUnit:null,
      //   cnDate: null,
      //   cnPromotionType:null,
      //   cnPostingDate:moment(this.selected.CnPostingDate).format('YYYY-MM-DD'),
      //   debitNote:null,
      //   costCenter:null,
      //   vat_in:0,
      //   taxReturnNumber: this.selected.TaxReturnNumber,
      //   taxReturnDate: moment(this.selected.TaxReturnDate).format('YYYY-MM-DD'),
      //   notes: '',
      //   dpp:0,
      //   withholdingTaxValue:0,
      //   vatInValue:0,
      // }
      console.log(this.selected.ID)
      if (this.selected.ID != 0) {
        this.getCustomer(this.selected.CustomerCode)
        this.findDebitNote(this.selected.DebitNoteID)
      }
    }
  },
  methods: {
    // this.fileAttachment = []
    //       resp.data.attachment.map(v => {
    //         this.fileAttachment.push({
    //           ListID: v.ListID,
    //           NameFile: v.NameFile,
    //           PathFile: v.PathFile,
    //         });
    //       })
    handleRate(){
      console.log(this.create.customer)
      this.create.cnPromotionType.RateOption.map(v => {
        if (this.create.customer.npwp_type == v.NpwpType) {
          this.create.cnPromotionType.Rate = v.Rate
          if (this.withholdingTax == "GROSS") {
            this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, ''))
          } else {
            this.create.amount = parseFloat(this.create.dpp.toString().replace(/,/g, '')) + parseFloat(this.create.vatInValue.toString().replace(/,/g, '')) - parseFloat(this.create.withholdingTaxValue.toString().replace(/,/g, ''))
          }
          this.create.amount = this.formatPrice(this.create.amount.toString().replace(/,/g, ''))
        }
        // console.log(v)
        // this.create.cnPromotionType
      })
      
      console.log(this.create.cnPromotionType)
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    uploadAttachment(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/credit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment = []
          resp.data.attachment.map(v => {
            this.fileAttachment.push({
              ListID: v.ListID,
              NameFile: v.NameFile,
              PathFile: v.PathFile,
            });
          })
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleShow(file) {
      let objectURL
      console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, '_blank').focus();
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        
        this.file = this.$refs.file.files;
        this.fileAttachment = []
        for(let i =0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i])
          // this.uploadData(i);
          if (this.create.id == 0) {
            this.fileAttachment.push({
              NameFile: this.file[i].name,
              File: this.file[i],
            });
          } else {
            this.uploadAttachment(i)
          }
          
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleDeleteAttachment(index) {
      const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r,i) => {
        return i != index 
      })
      if (this.create.id != 0) {
        this.$vs.loading();
        var form = new FormData();
        form.append("list_id", deleteAttachment.ListID);
        form.append("id", this.selected.ID);
        this.$http
          .post("/api/v1/credit-note/delete-file", form, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.fileAttachment = this.fileAttachment.filter(v => {
                return v.ListID != deleteAttachment.ListID
              })
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
      }
      
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    // onFromChange(selectedDates, dateStr, instance) {
      // console.log(instance);
      // this.$set(this.configTodateTimePicker, "minDate", dateStr);
    // },
    getOptionOU() {
      this.$http.get("/api/v1/master/operating-unit").then((resp) => {
        this.option.territory = []
        this.option.operatingUnit = []
        resp.data.records.map(r => {
          let array = {
            operating_unit_id: r.ID,
            operating_unit_code: r.Code,
            operating_unit_name: r.Name,
            territory_id: r.OperatingUnitTerritory.TerritoryID,
            territory_code: r.OperatingUnitTerritory.Territory.code,
            territory_name: r.OperatingUnitTerritory.Territory.name,
          }
          if (this.selected.ID != 0 && this.selected.TerritoryID == r.OperatingUnitTerritory.TerritoryID) {
            this.create.territory =  array
          }
          console.log(this.selected.OperatingUnitID,r.ID)
          if (this.selected.ID != 0 && this.selected.OperatingUnitID == r.ID) {
            this.create.operatingUnit =  array
          }
          console.log(this.create.operatingUnit)
          this.option.territory.push(array)
          this.option.operatingUnit.push(array)
        })
        this.immutable = false
        // this.optionOU = resp.data.records;
        // this.operatingUnit = resp.data.records[0];
      });
    },
    handleSubmit() {
      if (this.create.customer.ID == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select customer.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }

      if (this.create.territory == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }
      
      

       if (this.create.operatingUnit == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select operating unit",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }
      if (this.create.cnPromotionType == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select cn promotion type",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }
      if (this.create.cnPromotionType.Rate == 0) {
        this.$vs.notify({
          title: "Error",
          text: "npwp type not match with  cn promotion type",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }
      if (this.create.costCenter == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select cost center",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return 
      }
      
      if (this.create.debitNote == null) {
        this.create.debitNote = {ID:0}
      }
      
      const request = {
        id: this.create.id,
        customer_id: this.create.customer.ID,
        customer_name: this.create.customer.Name,
        customer_code: this.create.customer.Code,
        territory_id: this.create.territory.territory_id,
        operating_unit_id: this.create.operatingUnit.operating_unit_id,
        debit_note_id: this.create.debitNote.ID,
        cost_center_id: this.create.costCenter.ID,
        cn_promotion_type: this.create.cnPromotionType.ID,
        mapping_gl: this.create.cnPromotionType.MappingGl,
        cn_posting_date: moment(this.create.cnPostingDate).format("YYYY-MM-DD"),
        cn_date: moment(this.create.cnDate).format("YYYY-MM-DD"),
        notes: this.create.notes,
        tax_return_date: moment(this.create.taxReturnDate).format("YYYY-MM-DD"),
        tax_return_number: this.create.taxReturnNumber,
        vat_in_value: parseFloat(this.create.vatInValue.toString().replace(/,/g, "").toString()),
        dpp: parseFloat(this.create.dpp.toString().replace(/,/g, "").toString()),
        withholding_tax_value: parseFloat(this.create.withholdingTaxValue.toString().replace(/,/g, "").toString()),
        withholding_tax_calculation: this.withholdingTax == 'MT' ? 'GROSS': 'NET',
        amount: parseFloat(this.create.amount.toString().replace(/,/g, "").toString())
      };
      console.log(this.create)
      console.log(request)
      const bypass = false
      if (bypass) {
        return
      }
      this.$vs.loading();
      const post = createCreditNoteManual(request);
      post.then((r) => {
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
          this.$nextTick(() => {
            // this.$emit("closeDetail");
            this.$vs.loading.close();
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$nextTick(() => {
            // this.$emit("closeDetail");
            this.$emit("closeDetail", true);
            this.$vs.loading.close();
          });
        }
      });
    },
    getGeneralForm() {
      this.$http.get('/api/v1/credit-note/form-general').then((r) => {
          // this.optionTerritory = r.data.territory
          this.option.cnPromotionType = r.data.creditNotePromotionType
          this.option.costCenter = r.data.costCenter
          this.option.withHoldingTax = r.data.withHoldingTaxs
          this.option.cnPromotionType.map(sub => {
            
            if (sub.ID == this.selected.CnPromoTypeID) {
              this.create.cnPromotionType = sub
            }

          })  

          this.option.costCenter.map(sub => {
            if (sub.ID == this.selected.CostCenterID) {
              this.create.costCenter = sub
            }
          })
        }).catch((e) => {
          console.log(e)
        })
    },
    formatPrice(angka) {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getCustomer(query) {
      if (query.length <= 2) {
        return
      }
      if (this.selected.ID != 0 ) {
        query = this.selected.CustomerCode
      }
      console.log(this.create, 'asd')
      this.$vs.loading();
      const customer = dataCustomer(query);
      customer.then((response) => {
        console.log(response)
        this.option.customer = [];
        this.$nextTick(()=>{
          response.data.customer.map((row) => {
            if (row.code == this.create.customer_code) {
              this.create.customer = row
            }
            this.option.customer.push(row);

          });
          this.$vs.loading.close();
        })
        return 
      });
    },
    getDebitNote(query) {
      if (query.length <= 2) {
        return
      }
      this.$vs.loading();
      this.$http.get('/api/v1/credit-note/form-search-debit-note/' + query).then((r) => {
          this.option.debitNote = r.data.debitNote
          this.$vs.loading.close();
        }).catch((e) => {
          console.log(e)
        })
    },
    findDebitNote(id) {
      this.$vs.loading();
      this.$http.get('/api/v1/credit-note/find-debit-note/' + id).then((r) => {
          this.create.debitNote = r.data.debitNote
          this.$vs.loading.close();
        }).catch((e) => {
          console.log(e)
        })
    },
  },
};
</script>
