var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row mb-12"},[_c('vs-button',{staticStyle:{"margin-bottom":"15px"},attrs:{"icon":"done","color":"primary"},on:{"click":function($event){return _vm.generateCN()}}},[_vm._v(" Generate Credit Note ")])],1),_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
        _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
        _vm.detailHide,
      ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"},[_c('th',{attrs:{"width":"15%"}},[_c('vs-checkbox',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addAllValidate()}},model:{value:(_vm.validateAll),callback:function ($$v) {_vm.validateAll=$$v},expression:"validateAll"}},[_vm._v(" Generate All ")])],1)]),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.salesReturn),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[_c('vs-checkbox',{attrs:{"color":"success","checked":_vm.validate.includes(tr.ID)},on:{"click":function($event){return _vm.addValidate(tr.ID)}}})],1),_c('vs-td',[[_c('vx-tooltip',{attrs:{"text":"View Credit Note"}},[_c('vs-button',{attrs:{"color":"primary","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.handleView(tr)}}})],1)]],2),_c('vs-td',[_vm._v(" SO Code : "+_vm._s(tr.SOCode)),_c('br'),_vm._v(" Customer Return Code : "+_vm._s(tr.SalesReturnCode == "" ? "-" : tr.SalesReturnCode)),_c('br'),_vm._v(" Type : "+_vm._s(_vm.getSalesReturnType(tr.SalesReturnID))+" "),(tr.SalesReturnCode == '')?[_vm._v(" "+_vm._s(tr.Type)+" ")]:_vm._e()],2),_c('vs-td',[_vm._v(" Code : "+_vm._s(tr.CustomerCode)),_c('br'),_vm._v(" Name : "+_vm._s(tr.CustomerName)),_c('br')]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(tr.Date))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.Note)+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
          _vm.detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          _vm.detailHide,
        ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),_c('table',{staticClass:"table"},[_c('thead',[_c('th',[_vm._v("Item")]),_c('th',[_vm._v("Qty")]),(_vm.selectedData.SalesReturnTypeID == 1)?_c('th',[_vm._v("Qty PutAway")]):_vm._e()]),_vm._l((_vm.salesReturnLineFilter(_vm.selectedData.ID)),function(dt){return [_c('tr',{key:dt},[_c('td',[_vm._v(" "+_vm._s(dt.ItemCode)+" - "+_vm._s(dt.ItemName)+" ("+_vm._s(dt.ItemUnit)+") ")]),_c('td',[_vm._v(_vm._s(dt.Quantity))]),(_vm.selectedData.SalesReturnTypeID == 1)?_c('td',[_vm._v(" "+_vm._s(dt.QuantityReceived)+" ")]):_vm._e()])]})],2)],1)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }