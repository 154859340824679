<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Note Code</span>
      </div>

      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.Code }}
      </div>
    </div>
    <div
      v-if="selected.Type !== 'Return'"
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 20px"
    >
      <div class="vx-col sm:w-1/3 w-full">
        <span id>Debit Note Code</span>
      </div>

      <div class="vx-col sm:w-2/3 w-full">
        {{ this.debitNote ? this.debitNote.Code : "-" }}
      </div>
    </div>
    <div
      v-if="selected.SalesReturnCode !== ''"
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 20px"
    >
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Return</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.SalesReturnCode == "" ? "-" : selected.SalesReturnCode }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.CustomerCode }} - {{ selected.CustomerName }}
      </div>
    </div>
    <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Tax Return Number</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input v-model="selected.TaxReturnNumber"></vs-input>
      </div>
    </div> -->
    <!-- <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Invoice</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full" v-if="payment.ID != 0">
        {{selected.InvoiceCreditNote != '' ? selected.InvoiceCreditNote : selected.InvoiceNumber != "" ?  selected.InvoiceNumber :  selected.InvoiceCode }} 
      </div>
      <div class="vx-col sm:w-3/3 w-full" v-if="selected.Type == 'Claim' && remaining > 0">
            <multiselect
            class="selectExample"
            v-model="invoiceData.selected"
            :options="invoiceData.options"
            :allow-empty="true"
            :group-select="false"
            :max-height="100"
            placeholder="Type to search"
            track-by="Code"
            label="Code"
            :multiple="true"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{
                    props.option.Code
                  }}</span>
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">{{
                    props.option.Code
                  }}</span>
                </div>
              </template>
            </multiselect>
      </div>
    </div> -->

    <div v-if="invoiceData.selected.length > 0">
      <table class="table">
        <thead>
          <th>Invoice Number</th>
          <th>Total</th>
          <th>Paid</th>
          <th>Remaining</th>
        </thead>
        <template v-for="invo in this.invoiceData.selected">
          <tr v-bind:key="invo.ID">
            <td>{{ invo.Code }}</td>
            <td>{{ formatPrice(invo.Total.toString()) }}</td>
            <td>
              {{
                invo.Remaining == 0
                  ? 0
                  : formatPrice((invo.Total - invo.Remaining).toString())
              }}
            </td>
            <td>
              {{
                invo.Remaining == 0
                  ? formatPrice(invo.Total.toString())
                  : formatPrice(invo.Remaining.toString())
              }}
            </td>
          </tr>
        </template>
      </table>
    </div>

    <br />
    <vs-divider style="width: 100%; margin-left: 2%"
      >Total Credit Note</vs-divider
    >

    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>DPP</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ formatPrice(selected.DPP.toString()) }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Tax</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ formatPrice(selected.TaxValue.toString()) }}
      </div>
    </div>
    <template v-if="selected.SalesReturnCode == ''">
      <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Total Value</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          {{ formatPrice(selected.TotalValue.toString()) }}
        </div>
      </div>
    </template>

    <!-- <div
      v-if="selected.SalesReturnCode != '' && selected.UsedValue == 0"
      class="mb-3"
    >
      <div class="vx-col w-full">
        <label class="vs-input--label">Note Reversal</label>
        <vs-textarea v-model="note_reversal" />
        <label class="vs-input--label">Reversal Date</label>
        <Datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="reversalDate"
          placeholder="Date Clearing"
        ></Datepicker>
      </div>
    </div> -->
    <!-- <div v-if="(selected.SalesReturnCode != '' && selected.UsedValue == 0 )" class="vx-col w-full">
        <br>
        <vs-button color="red" class="mb-2" v-on:click="handleReversal">Reversal CN </vs-button>
    </div> -->
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col mt-4">
        <div class="flex gap-3 mb-2">
          <vs-button v-on:click="handleSave">Save</vs-button>
          <vs-button
            @click="handleOpenPrompt"
            v-if="
              isReverseAllowed(selected.Type, selected.Sources, selected.Status)
            "
            color="danger"
            >Reverse</vs-button
          >
          <vs-prompt
            v-validate="'required'"
            title="Confirmation"
            color="danger"
            @accept="handleReverse"
            @cancel="handleClose"
            @close="handleClose"
            :buttons-hidden="false"
            :active.sync="showApprovalPrompt"
          >
            <div class="flex flex-col gap-4">
              <p>
                Are you sure you want to reverse
                <span class="font-bold">{{ selected.Code }}?</span>
              </p>
              <!-- <div class="flex flex-col gap-1">
                <span>Reversal date</span>
                <datepicker
                  class="w-full"
                  v-validate="'required'"
                  :inline="false"
                  v-model="reversalDate"
                  placeholder="Date Clearing"
                  name="reversal date"
                  :disabled-dates="{ from: new Date() }"
                />
                <span
                  v-show="errors.has('reversal date')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("reversal date") }}
                </span>
              </div> -->
              <div class="flex flex-col gap-1">
                <span>Reversal Note</span>
                <vs-textarea v-model="note_reversal" />
              </div>
            </div>
          </vs-prompt>
        </div>
      </div>
      <!-- <div
        v-if="selected.SalesReturnCode != '' && selected.UsedValue == 0"
        class="vx-col"
      >
        <br />
        <vs-button color="red" class="mb-2" v-on:click="handleReversal"
          >Reversal CN
        </vs-button>
      </div> -->
    </div>

    <!-- <div
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 20px"
      v-if="selected.Type == 'Claim' && remaining > 0"
    >
      <div class="vx-col sm:w-1/3 w-full"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
          Update
        </vs-button>
      </div>
    </div> -->

    <vs-divider
      v-if="selected.SalesReturnCode != ''"
      style="width: 100%; margin-left: 2%"
      >List Item</vs-divider
    >
    <table v-if="selected.SalesReturnCode != ''" class="table">
      <thead>
        <th>Item Code</th>
        <th>Item Name</th>
        <th>Qty</th>
        <th>CR Value</th>
        <th>CN Value</th>
      </thead>
      <template v-for="line in lines">
        <tr v-bind:key="line.ID">
          <td>{{ line.ItemCode }}</td>
          <td>{{ line.ItemName }}</td>
          <td>{{ line.Qty }}</td>
          <td>
            Price Total : {{ formatPrice(line.PriceSo + line.ReturnDiscount)
            }}<br />
            Discount : {{ formatPrice(line.ReturnDiscount) }}<br />
            Subtotal Total : {{ formatPrice(line.PriceSo.toString()) }}<br />
            Tax Amount : {{ formatPrice(line.ReturnTax.toString()) }}<br />
            Total : {{ formatPrice(line.ReturnTotal.toString()) }}<br />
          </td>
          <td>
            Price Total : {{ formatPrice(line.PriceSo + line.ReturnDiscount)
            }}<br />
            Discount : {{ formatPrice(line.ReturnDiscount) }}<br />
            Subtotal Total : {{ formatPrice(line.PriceCn.toString()) }}<br />
            Tax Amount : {{ formatPrice(line.TaxAmount.toString()) }}<br />
            Total : {{ formatPrice(line.Total.toString()) }}<br />
          </td>
        </tr>
      </template>
      <tr>
        <td></td>
        <td></td>
        <td>{{ totalQty }}</td>
        <td>
          <font style="font-weight: bold">Price Total</font> :
          {{ formatPrice(this.ReturnPriceTotal) }}<br />
          <font style="font-weight: bold">Discount</font> :
          {{ formatPrice(this.ReturnDiscount) }}<br />
          <font style="font-weight: bold">Subtotal Total</font> :
          {{ formatPrice(this.ReturnSubtotalTotal.toString()) }}<br />
          <font style="font-weight: bold">Tax Amount</font> :
          {{ formatPrice(this.ReturnTaxAmount.toString()) }}<br />
          <font style="font-weight: bold">Total</font> :
          {{ formatPrice(this.ReturnTotal.toString()) }}<br />
        </td>
        <td>
          <font style="font-weight: bold">Price Total</font> :
          {{ formatPrice(this.PriceTotal) }}<br />
          <font style="font-weight: bold">Discount</font> :
          {{ formatPrice(this.Discount) }}<br />
          <font style="font-weight: bold">Subtotal Total</font> :
          {{ formatPrice(this.SubtotalTotal.toString()) }}<br />
          <font style="font-weight: bold">Tax Amount</font> :
          {{ formatPrice(this.TaxAmount.toString()) }}<br />
          <font style="font-weight: bold">Total</font> :
          {{ formatPrice(this.Total.toString()) }}<br />
        </td>
      </tr>
    </table>
    <!-- <div v-if="(selected.SalesReturnCode != '' && selected.UsedValue == 0 )" class="mb-3">
      <div class="vx-col w-full">
        <label class="vs-input--label">Reversal Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="reversalDate"
          placeholder="Date Clearing"
        ></datepicker>
      </div>
    </div>
    <div v-if="(selected.SalesReturnCode != '' && selected.UsedValue == 0 )" class="vx-col w-full">
        <br>
        <vs-button color="red" class="mb-2" v-on:click="handleReversal">Reversal Payment </vs-button>
    </div> -->
  </div>
</template>
<script>
import { dataCreditNote } from "../../../../services/api/credit_note";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    applied: Boolean,
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      lines: [],
      payment: {},
      totalQty: 0,
      totalSoValue: 0,
      totalCnValue: 0,
      ReturnPriceTotal: 0,
      PriceTotal: 0,
      ReturnDiscount: 0,
      Discount: 0,
      ReturnSubtotalTotal: 0,
      SubtotalTotal: 0,
      ReturnTaxAmount: 0,
      TaxAmount: 0,
      UsedValue: 5,
      ReturnTotal: 0,
      InvoiceNumber: "",
      note_reversal: "",
      Total: 0,
      invoiceData: {
        selected: [],
        options: [],
      },
      responseData: {},
      allowedReverseType: [
        "Off Invoice Principal MT MIX",
        "Off Invoice Principal GT MIX",
      ],
      allowReverseSource: ["CN Manual"],
      showApprovalPrompt: false,
      reversalDate: null,
      debitNote: null,
    };
  },
  computed: {},
  watch: {
    "selected.ID"() {
      this.getInvoiceData(this.selected.CustomerCode);
      this.reloadData();
      this.selected.TotalValue = this.formatPrice(this.selected.TotalValue);
      this.selected.DPP = this.formatPrice(this.selected.DPP);
      this.selected.TaxValue = this.formatPrice(this.selected.TaxValue);
      console.log("watch this.selected", this.selected);
    },
  },
  mounted() {
    console.log("applied", this.applied);
    console.log("selected", this.selected);
    this.getInvoiceData(this.selected.CustomerCode);
    this.reloadData();
    this.reversalDate = new Date();
    this.remaining =
      this.selected.TotalValue.toString().replace(",", "") -
      this.selected.UsedValue;
    console.log("remaining", this.remaining);
    console.log("mounted this.selected", this.selected);
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("DD/MM/YYYY hh:mm");
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    reloadData() {
      this.$vs.loading();

      if (this.selected.InvoiceCreditNote) {
        this.InvoiceNumber = this.selected.InvoiceCreditNote;

        if (this.selected.Type == "Claim") {
          this.setInvoiceSelected(this.InvoiceNumber);
        }
      }

      const creditNote = dataCreditNote(this.selected);
      creditNote.then((r) => {
        this.$vs.loading.close();
        console.log(r);
        console.log(">>>>", r.data.creditNote.UsedValue);
        this.lines = [];
        this.ReturnPriceTotal = 0;
        this.PriceTotal = 0;
        this.ReturnDiscount = 0;
        this.Discount = 0;
        this.ReturnSubtotalTotal = 0;
        this.SubtotalTotal = 0;
        this.ReturnTaxAmount = 0;
        this.TaxAmount = 0;
        this.ReturnTotal = 0;
        this.Total = 0;
        this.UsedValue = r.data.creditNote.UsedValue;
        r.data.creditNoteLine.map((v) => {
          this.ReturnPriceTotal =
            this.ReturnPriceTotal + (v.PriceSo + v.ReturnDiscount);
          this.PriceTotal = this.PriceTotal + (v.PriceCn + v.ReturnDiscount);
          this.ReturnDiscount = this.ReturnDiscount + v.ReturnDiscount;
          this.Discount = this.Discount + v.ReturnDiscount;
          this.ReturnSubtotalTotal = this.ReturnSubtotalTotal + v.PriceSo;
          this.SubtotalTotal = this.SubtotalTotal + v.PriceCn;
          this.ReturnTaxAmount = this.ReturnTaxAmount + v.ReturnTax;
          this.TaxAmount = this.TaxAmount + v.TaxAmount;
          this.ReturnTotal = this.ReturnTotal + v.ReturnTotal;
          this.Total = this.Total + v.Total;
          this.totalQty = this.totalQty + v.Qty;
          this.lines.push(v);
        });
        this.payment = r.data.payment;
        this.totalCnValue = this.totalCnValue.toString().replace(".", ",");
        this.totalSoValue = this.totalSoValue.toString().replace(".", ",");
        this.debitNote = r.data.debitNote ? r.data.debitNote : null;
        console.log(this.debitNote, "dn");
        console.log(this.lines, "lines");
      });
    },

    handleView(selected) {
      console.log(selected);
      this.$vs.loading();
    },
    handleDelete(selected) {
      console.log(selected);
    },
    formatPrice(angka) {
      const amount = parseFloat(angka).toFixed(2);
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getInvoiceData(CustomerCode) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/invoice/reference", {
          params: {
            order: "asc",
            sort: "code",
            customer_code: CustomerCode,
            Length: 100,
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.invoiceData.options = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setInvoiceSelected(InvoiceCode) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/invoice/reference/" + InvoiceCode)
        .then((resp) => {
          if (resp.status == "success") {
            this.invoiceData.selected = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSubmit() {
      console.log(this.selected);
      this.putData(this.selected.ID);
    },
    handleReversal() {
      console.log("selected", this.selected);
      console.log("selected ID", this.selected.ID);
      const params = {
        id: this.selected.ID,
        date_reversal: moment(this.reversalDate).format("YYYY-MM-DD"),
        note_reversal: this.note_reversal,
      };
      console.log("params", params);
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/credit-note/WaitingApprovalReversal", params)
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.closeDetail();
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed, " + result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleSave() {
      const params = {
        tax_return_number: this.selected.TaxReturnNumber,
      };
      console.log("params", params);
      this.$vs.loading();
      //console.log(params)
      this.$http
        .put(
          `/api/v1/credit-note/update-tax-return-number/${this.selected.ID}`,
          params
        )
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed, " + result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.closeDetail();
        })
        .catch((e) => {});
    },
    paramData() {
      var invoiceNumbers = [];
      if (Array.isArray(this.invoiceData.selected)) {
        this.invoiceData.selected.forEach(function (item) {
          invoiceNumbers.push(item.Code);
        }, invoiceNumbers);
      }
      return {
        invoice_numbers: invoiceNumbers,
        applied: this.applied ? true : false,
      };
    },
    putData(id) {
      this.$vs.loading();
      this.$http
        .put("/api/v1/credit-note/invoice/" + id, this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.closeDetail();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    closeDetail() {
      window.scrollTo(0, 0);
      this.$emit("close", true);
    },
    isReverseAllowed(type, source, status) {
      return (
        (this.allowedReverseType.includes(type) ||
          this.allowReverseSource.includes(source) ||
          this.selected.SalesReturnCode != "") &&
        this.selected.UsedValue == 0 &&
        status == 4
      );
    },
    handleOpenPrompt() {
      this.showApprovalPrompt = true;
    },
    handleClose() {
      this.showApprovalPrompt = false;
    },
    handleReverse() {
      this.$vs.loading();
      this.$validator
        .validateAll()
        .then(() => {
          this.$http
            .patch(`api/v1/credit-note/update-status/${this.selected.ID}`, {
              status: 5, //waiting reverse
              // reversal_date: moment(this.reversalDate).format("YYYY-MM-DD"),
              reversal_note: this.note_reversal,
            })
            .then((result) => {
              console.log(result);
              this.$vs.loading.close();

              if (result.code == 200) {
                this.closeDetail();
                this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: result.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
